@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  display: grid;
  row-gap: px-to-rem(16px);
  padding-top: px-to-rem(16px);
  padding-bottom: px-to-rem(20px);
  border-bottom: px-to-rem(1px) solid var(--devider-clr);

  &:last-child {
    border-bottom: 0 none;
  }

  @media (min-width: $table-breakpoint) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @media (min-width: $desktop-breakpoint) {
    display: none;
  }

  &Row {
    display: flex;
    flex-direction: column;
    row-gap: px-to-rem(4px);
    overflow: hidden;
  }

  &Title {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
    color: var(--gray);
  }

  &RequestStatus {
    @media (min-width: $table-breakpoint) {
      display: none;
    }
  }

  &Value {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
    color: var(--black);
  }

  &Wallet {
    @media (min-width: $table-breakpoint) {
      grid-row: 2;
      grid-column: 1/3;
    }
  }

  &WalletName {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
