@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.user {
  display: flex;
  flex: 2;
  max-width: px-to-rem(486px);
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: px-to-rem(32px);

  @media (min-width: $table-breakpoint) {
    margin: 0 auto;
  }

  @media (min-width: $desktop-breakpoint) {
    padding-top: 0;
  }
}

.form {
  padding-top: px-to-rem(24px);
  display: flex;
  flex-direction: column;
  row-gap: px-to-rem(24px);
}

.errorMsg {
  margin-top: px-to-rem(20px);
}
