@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.locale {
  display: flex;
  column-gap: px-to-rem(6px);
  font-weight: 500;
  font-size: px-to-rem(14px);
  line-height: px-to-rem(26px);
  color: var(--text-black);
  cursor: pointer;
  position: relative;

  @media (min-width: $desktop-breakpoint) {
    background: var(--white);
    box-shadow: 0 px-to-rem(8px) px-to-rem(32px) var(--box-shadow-clr);
    border-radius: px-to-rem(100px);
    padding: px-to-rem(7px) px-to-rem(16px);
    column-gap: px-to-rem(12px);
  }

  &Menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: var(--white);
    box-shadow: 0 px-to-rem(8px) px-to-rem(32px) var(--box-shadow-clr);
    border-radius: px-to-rem(20px);
    top: px-to-rem(28px);
    left: 0;
    z-index: 1;

    @media (min-width: $desktop-breakpoint) {
      top: px-to-rem(48px);
    }

    span {
      padding: px-to-rem(8px) px-to-rem(14px);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &Icon {
    transform: rotate(90deg);

    path {
      fill: var(--arrow-clr);
    }
  }
}
