@import 'src/shared/lib/px-to-rem';

.container {
  background: var(--error-bg);
  border-radius: px-to-rem(8px);
  font-weight: 500;
  font-size: px-to-rem(14px);
  color: var(--error-clr);
  line-height: px-to-rem(26px);
  padding: px-to-rem(9px) px-to-rem(16px) px-to-rem(9px) px-to-rem(16px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Icon {
    cursor: pointer;

    path {
      fill: var(--error-clr);
    }
  }
}
