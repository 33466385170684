@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  display: flex;
  flex-direction: column;
}

.lotuses {
  display: flex;
  flex-wrap: wrap;
  column-gap: px-to-rem(9px);
  row-gap: px-to-rem(12px);
  padding-top: px-to-rem(24px);
  flex: 2;
  padding-bottom: px-to-rem(32px);

  @media (min-width: $table-breakpoint) {
    column-gap: px-to-rem(22px);
  }

  @media (min-width: $desktop-breakpoint) {
    column-gap: px-to-rem(21px);
  }
}

.title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: var(--text-black);
  flex: 2;

  @media (min-width: $desktop-breakpoint) {
    font-size: px-to-rem(24px);
    line-height: px-to-rem(32px);
  }
}

.content {
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: $desktop-breakpoint) {
    flex-direction: initial;
  }

  &Columns {
    display: flex;
    flex-direction: column;
    flex: 2;

    @media (min-width: $desktop-breakpoint) {
      padding-right: px-to-rem(84px);
    }
  }
}

.rate {
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(-0.078px);
  color: var(--text-black);
  padding-top: px-to-rem(12px);
  padding-bottom: px-to-rem(25px);

  @media (min-width: $desktop-breakpoint) {
    padding-bottom: px-to-rem(8px);
  }
}

.anotherValue {
  display: flex;
  align-items: center;
  column-gap: px-to-rem(8px);
  font-weight: 600;
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  color: var(--purple-dark);
  padding: px-to-rem(15px) 0 px-to-rem(35px) 0;
}

.replenishment {
  max-width: 169px;
  font-weight: 700;
  font-size: px-to-rem(16px);
  line-height: px-to-rem(22px);
  padding: px-to-rem(17px) px-to-rem(40px);
}

.agreements {
  align-items: center;
  display: flex;
  column-gap: px-to-rem(12px);
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(-0.078px);
  color: var(--gray);
  padding-bottom: px-to-rem(34px);

  p {
    max-width: px-to-rem(400px);
    white-space: pre-wrap;
  }
}

.ruble {
  font-weight: 600;
  font-size: px-to-rem(18px);
  line-height: px-to-rem(24px);
  color: var(--purple-bright);
  font-style: normal;
  padding-right: px-to-rem(8px);
  width: px-to-rem(24px);
  height: px-to-rem(24px);
}

.coinIcon {
  width: px-to-rem(24px);
  height: px-to-rem(24px);
}

.userBalance {
  height: initial;
  margin-bottom: px-to-rem(24px);

  @media (min-width: $desktop-breakpoint) {
    margin-bottom: initial;
  }
}
