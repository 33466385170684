:root {
  --text-black: #1c1c1e;
  --main-bg: #F6F6F9;
  --white: #fff;
  --purple-light: rgba(168, 167, 255, 0.16);
  --purple-super-ligth: rgba(255, 255, 255, 0.48);
  --purple-dark: #5856D6;
  --devider-clr: #e7e7f1;
  --black: #000000;
  --gray: #7E7E8F;
  --gray-border-bg: #E6E6ED;
  --gray-placeholder-clr: rgba(126, 126, 143, 0.72);
  --shadow-clr: rgba(88, 86, 214, 0.24);
  --purple-bg: rgba(160, 158, 255, 0.12);
  --gray-bg: #F8F8FA;
  --green-light-bg: rgba(66, 236, 195, 0.14);
  --green-light-clr: #1CC29A;
  --gray-circle: #C7C7CC;
  --black-light: #66667A;
  --gray-bg-card: #F5F5F9;
  --purple-bright: #AF52DE;
  --error: indianred;
  --box-shadow-clr: #E8E8F1;
  --arrow-clr: #AEAEB2;
  --error-bg: rgba(255, 59, 48, 0.1);
  --error-clr: #DB2218;
  --pending-status: rgba(236, 147, 66, 0.12);
  --completed-status: rgba(30, 228, 98, 0.12);
  --pending-clr: #E17F37;
  --completed-clr: #31C263;
  --cancelled-clr: #85858A;
  --error-buy-bg: rgba(235, 87, 87, 0.12);
}
