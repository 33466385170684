@import 'src/shared/lib/px-to-rem';

.container {
  display: flex;
  flex-direction: column;
  row-gap: px-to-rem(6px);
}

.label {
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  color: var(--gray);
}

.textarea {
  background-color: transparent;
  resize: none;
  outline: none;
  border: px-to-rem(1px) solid var(--gray-border-bg);
  border-radius: px-to-rem(8px);
  padding: px-to-rem(16px) px-to-rem(20px);

  &:hover, &:focus, &:active {
    border-color: var(--gray-border-bg) !important;
  }

  &::placeholder {
    color: var(--gray-placeholder-clr);
  }
}

.errorTextarea {
  border: px-to-rem(1px) solid var(--error);
}

.error {
  color: var(--error);
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  padding-top: px-to-rem(6px);
}
