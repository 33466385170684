@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  background: radial-gradient(30.03% 55.93% at 3% 24.59%, #ffffff 0%, rgba(255, 255, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    linear-gradient(360deg, #ffffff 4.92%, rgba(255, 255, 255, 0) 29.1%);
  box-shadow: 0 px-to-rem(24px) px-to-rem(56px) rgba(132, 132, 179, 0.12);
  backdrop-filter: blur(px-to-rem(36px));
  border-radius: px-to-rem(24px);
  position: relative;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--purple-bg);
  border-radius: 50%;
  position: absolute;
  width: px-to-rem(40px);
  height: px-to-rem(40px);
  right: px-to-rem(20px);
  top: px-to-rem(20px);
  z-index: 1;

  @media (min-width: $table-breakpoint) {
    width: px-to-rem(50px);
    height: px-to-rem(50px);
  }
}
