@import 'src/shared/lib/px-to-rem';

.button {
  cursor: pointer;
  background: var(--white);
  transition: 0.3s;
  position: relative;

  &:hover {
    opacity: 0.8;
  }

  &OutLine {
    background: transparent;
    border: 0 none;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
  }

  &Default {
    background: var(--purple-dark);
    box-shadow: 0 px-to-rem(4px) px-to-rem(12px) var(--shadow-clr);
    border-radius: px-to-rem(12px);
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
    color: var(--white);
    padding: px-to-rem(13px) px-to-rem(32px);
    display: flex;
    justify-content: center;
  }

  &Rounded {
    border-radius: px-to-rem(100px);
    padding: px-to-rem(7px) px-to-rem(25px) px-to-rem(7px) px-to-rem(25px);
    font-weight: 500;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(26px);
  }

  &Disabled {
    cursor: unset;
    color: var(--gray) !important;

    &:hover {
      opacity: 1;
    }
  }

  &Icon {
    align-items: center;
    column-gap: px-to-rem(12px);
    display: flex;

    &PosLeft {
      flex-direction: row-reverse;
    }

    &PosRight {
      flex-direction: row;
    }
  }
}
