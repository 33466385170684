@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.coinIcon {
  width: px-to-rem(24px);
  height: px-to-rem(24px);
}

.ruble {
  font-weight: 600;
  font-size: px-to-rem(18px);
  line-height: px-to-rem(24px);
  color: var(--purple-bright);
  font-style: normal;
  padding-right: px-to-rem(8px);
  width: px-to-rem(24px);
  height: px-to-rem(24px);
}

.rate {
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(-0.078px);
  color: var(--text-black);
  padding-top: px-to-rem(12px);
  padding-bottom: px-to-rem(25px);

  @media (min-width: $desktop-breakpoint) {
    padding-bottom: px-to-rem(8px);
  }
}
