@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
}

.title {
  font-weight: 700;
  font-size: px-to-rem(20px);
  line-height: px-to-rem(28px);
  color: var(--text-black);
}

.desktopContent {
  display: none;

  @media (min-width: $desktop-breakpoint) {
    display: initial;
  }

  &Table {
    display: flex;
    flex-direction: column;

    &Cell {
      font-size: px-to-rem(14px);
      line-height: px-to-rem(22px);
      color: var(--black);
    }

    &Header {
      display: grid;
      grid-template-columns: 15% 20% 50% 15%;
      padding: px-to-rem(28px) 0 px-to-rem(16px) 0;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(22px);
      color: var(--gray);
    }

    &Body {
      display: flex;
      flex-direction: column;
    }

    &Row {
      border-top: px-to-rem(1px) solid var(--devider-clr);
      display: grid;
      grid-template-columns: 15% 20% 50% 15%;
      padding: px-to-rem(16px) 0 px-to-rem(16px) 0;
      width: 100%;
    }
  }
}
