@import 'src/shared/lib/px-to-rem';

.formTextfields {
  display: flex;
  flex-direction: column;
  padding-bottom: px-to-rem(36px);
}

.formSubmit {
  padding: px-to-rem(13px) px-to-rem(32px);
  max-width: px-to-rem(156px);
  width: 100%;
}
