@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  padding-top: px-to-rem(52px);
}

.paper {
  padding: px-to-rem(20px) px-to-rem(16px) px-to-rem(16px) px-to-rem(16px);

  @media (min-width: $table-breakpoint) {
    padding: px-to-rem(72px) px-to-rem(16px) px-to-rem(96px) px-to-rem(16px);
  }

  &Title {
    font-weight: 700;
    font-size: px-to-rem(20px);
    line-height: px-to-rem(28px);
    color: var(--text-black);
    max-width: px-to-rem(480px);
    margin: 0 auto;

    @media (min-width: $table-breakpoint) {
      font-size: px-to-rem(28px);
      line-height: px-to-rem(36px);
    }

    @media (min-width: $table-breakpoint) {
      font-size: px-to-rem(32px);
      line-height: px-to-rem(48px);
    }
  }

  &Form {
    display: flex;
    flex-direction: column;
    row-gap: px-to-rem(24px);
    padding-top: px-to-rem(20px);
    max-width: px-to-rem(480px);
    margin: 0 auto;

    @media (min-width: $table-breakpoint) {
      padding-top: px-to-rem(36px);
    }
  }

  &FormSubmit {
    max-width: px-to-rem(141px);
    font-weight: 700;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
  }

  &FormTextarea {
    max-height: px-to-rem(168px);
  }
}
