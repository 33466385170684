@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.tab {
  font-weight: 500;
  font-size: px-to-rem(16px);
  line-height: px-to-rem(22px);
  color: var(--black-light);
  padding: px-to-rem(20px) px-to-rem(16px) px-to-rem(15px) px-to-rem(16px);
  cursor: pointer;

  &:last-child {
    white-space: nowrap;
  }

  @media (min-width: $desktop-breakpoint) {
    padding: px-to-rem(20px) px-to-rem(24px);
  }

  &:hover {
    color: var(--black);
  }

  &Active {
    color: var(--black);
    border-bottom: px-to-rem(2px) solid var(--black);
  }
}

.container {
  display: flex;
  border-bottom: px-to-rem(1px) solid var(--devider-clr);
  border-radius: 12px;
  overflow-x: auto;
}
