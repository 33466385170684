@import 'src/shared/lib/px-to-rem';

.container {
  align-items: center;
  position: relative;
  display: flex;
  column-gap: px-to-rem(12px);

  span {
    font-size: px-to-rem(16px);
    line-height: px-to-rem(22px);
    color: var(--black);
  }
}

.label {
  display: flex;
  font-size: px-to-rem(16px);
  line-height: px-to-rem(22px);
  color: var(--black);
  width: px-to-rem(32px);
  height: px-to-rem(32px);
  border-radius: 50%;
  border: px-to-rem(1px) solid var(--purple-dark);
  position: relative;

  &Icon {
    position: absolute;
    width: 16px;
    background: var(--purple-dark);
    height: 16px;
    border-radius: 50%;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
  }
}

.input {
  position: absolute;
  visibility: hidden;
}
