@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  display: flex;
  flex-direction: column;
}

.lotuses {
  display: flex;
  flex-wrap: wrap;
  column-gap: px-to-rem(21px);
  row-gap: px-to-rem(12px);
  padding-top: px-to-rem(24px);
  flex: 2;
  padding-bottom: px-to-rem(32px);
}

.title {
  font-weight: 700;
  font-size: px-to-rem(20px);
  line-height: px-to-rem(28px);
  color: var(--text-black);
  flex: 2;

  @media (min-width: $desktop-breakpoint) {
    font-size: px-to-rem(24px);
    line-height: px-to-rem(32px);
  }
}

.content {
  display: flex;
  flex-direction: column-reverse;
  row-gap: px-to-rem(32px);

  @media (min-width: $desktop-breakpoint) {
    flex-direction: initial;
  }

  &Columns {
    display: flex;
    flex-direction: column;
    flex: 2;

    @media (min-width: $desktop-breakpoint) {
      padding-right: px-to-rem(84px);
    }
  }
}

.rate {
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(-0.078px);
  color: var(--gray);
  padding-top: px-to-rem(20px);
  padding-bottom: px-to-rem(40px);
}

.anotherValue {
  display: flex;
  align-items: center;
  column-gap: px-to-rem(8px);
  padding: px-to-rem(20px) 0 px-to-rem(35px) 0;
  color: var(--purple-dark);
  font-weight: 600;
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  position: relative;
}

.replenishment {
  max-width: 169px;
  font-weight: 700;
  font-size: px-to-rem(16px);
  line-height: px-to-rem(22px);
  padding: px-to-rem(17px) px-to-rem(40px);
}

.agreements {
  align-items: center;
  display: flex;
  column-gap: px-to-rem(12px);
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  letter-spacing: px-to-rem(-0.078px);
  color: var(--gray);
  padding-bottom: px-to-rem(34px);

  p {
    max-width: px-to-rem(400px);
    white-space: pre-wrap;
  }
}

.convert {
  display: flex;
  column-gap: px-to-rem(15px);
  width: 100%;
  padding-top: px-to-rem(32px);
  flex-direction: column;
  row-gap: px-to-rem(12px);

  @media (min-width: $table-breakpoint) {
    flex-direction: initial;
    row-gap: 0;
  }

  &TransactionTextField {
    flex: 1;
  }
}

.btc {
  font-weight: 600;
  font-size: px-to-rem(18px);
  line-height: px-to-rem(24px);
  color: var(--purple-bright);
  font-style: normal;
  width: px-to-rem(24px);
  height: px-to-rem(24px);
}

.coinIcon {
  width: px-to-rem(24px);
  height: px-to-rem(24px);
}

.textFieldDisabled {
  background: var(--gray-bg-card);
}

.radios {
  padding-top: px-to-rem(27px);

  p {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
    color: var(--gray);
    padding-bottom: px-to-rem(8px);
  }

  &Content {
    display: flex;
    column-gap: px-to-rem(32px);
    flex-direction: column;
    row-gap: px-to-rem(12px);
    padding-top: px-to-rem(12px);
    padding-bottom: px-to-rem(10px);

    @media (min-width: $table-breakpoint) {
      flex-direction: initial;
      padding-top: initial;
      padding-bottom: initial;
    }
  }
}

.userBalance {
  height: initial;
}

.errorMsg {
  margin-top: px-to-rem(16px);
}

.eWalletPopup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  max-width: px-to-rem(519px);
  background: var(--white);
  box-shadow: 0 px-to-rem(16px) px-to-rem(32px) var(--shadow-clr);
  border-radius: px-to-rem(16px);
  width: 100%;
  z-index: 1;
  font-weight: 400;
  top: px-to-rem(48px);
  padding: px-to-rem(24px) px-to-rem(60px) px-to-rem(28px) px-to-rem(28px);
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);
  color: var(--text-black);
}

.close {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--purple-bg);
  border-radius: 50%;
  position: absolute;
  width: px-to-rem(40px);
  height: px-to-rem(40px);
  right: px-to-rem(12px);
  top: px-to-rem(12px);
  z-index: 1;
}

.source {
  padding-top: px-to-rem(20px);
}
