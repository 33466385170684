@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.content {
  padding-bottom: px-to-rem(156px);
  height: 100vh;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: px-to-rem(16px);
  margin-top: px-to-rem(59px);

  @media (min-width: $desktop-breakpoint) {
    padding-top: px-to-rem(32px);
    padding-bottom: px-to-rem(48px);
  }
}
