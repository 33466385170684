@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.form {
  display: flex;
  flex-direction: column;
  padding-top: px-to-rem(20px);

  @media (min-width: $table-breakpoint) {
    padding-top: px-to-rem(32px);
    padding-bottom: px-to-rem(57px);
  }

  &Textfields {
    display: flex;
    flex-direction: column;
    row-gap: px-to-rem(24px);
    padding-bottom: px-to-rem(12px);
  }

  &ForgetPsw {
    font-size: px-to-rem(12px);
    line-height: px-to-rem(16px);
    color: var(--purple-dark);
  }

  &Submit {
    max-width: px-to-rem(107px);
    width: 100%;
    font-weight: 700;
    margin-top: px-to-rem(36px);
    margin-bottom: px-to-rem(32px);

    @media (min-width: $table-breakpoint) {
      margin-bottom: 0;
    }
  }
}
