@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  padding: px-to-rem(16px);
  position: relative;
  margin-top: px-to-rem(52px);

  @media (min-width: $table-breakpoint) {
    padding-top: px-to-rem(64px);
    padding-bottom: px-to-rem(80px);
  }
}

.errorMsg {
  margin-top: px-to-rem(32px);
}

.content {
  max-width: px-to-rem(436px);
  width: 100%;
  margin: 0 auto;
}

.title {
  font-weight: 700;
  color: var(--text-black);
  font-size: px-to-rem(20px);
  line-height: px-to-rem(28px);

  @media (min-width: $table-breakpoint) {
    font-size: px-to-rem(28px);
    line-height: px-to-rem(36px);
  }

  @media (min-width: $desktop-breakpoint) {
    font-size: px-to-rem(32px);
    line-height: px-to-rem(48px);
  }
}

.register {
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);
  color: var(--text-black);
  display: flex;
  flex-direction: column;

  @media (min-width: $table-breakpoint) {
    display: initial;
  }

  span {
    color: var(--purple-dark);
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--purple-bg);
  border-radius: 50%;
  position: absolute;
  width: px-to-rem(50px);
  height: px-to-rem(50px);
  right: px-to-rem(20px);
  top: px-to-rem(20px);
}
