@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.acceptIcon {
  width: px-to-rem(80px);
  height: px-to-rem(80px);
  border-radius: 50%;
  background: var(--green-light-bg);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $table-breakpoint) {
    width: px-to-rem(120px);
    height: px-to-rem(120px);
  }
}

.errorIcon {
  width: px-to-rem(80px);
  height: px-to-rem(80px);
  border-radius: 50%;
  background: var(--error-buy-bg);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $table-breakpoint) {
    width: px-to-rem(120px);
    height: px-to-rem(120px);
  }
}

.container {
  padding-top: px-to-rem(52px);
}

.paper {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: px-to-rem(91px) 0 px-to-rem(121px) 0;
}

.title {
  font-weight: 700;
  font-size: px-to-rem(20px);
  line-height: px-to-rem(28px);
  text-align: center;
  color: var(--text-black);
  padding-top: px-to-rem(32px);
  max-width: px-to-rem(256px);

  @media (min-width: $table-breakpoint) {
    max-width: px-to-rem(263px);
    font-size: px-to-rem(18px);
  }

  @media (min-width: $desktop-breakpoint) {
    font-size: px-to-rem(20px);
  }
}

.description {
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  text-align: center;
  letter-spacing: px-to-rem(-0.078px);
  color: var(--gray);
  max-width: px-to-rem(246px);
  padding-top: px-to-rem(8px);
  padding-bottom: px-to-rem(32px);
  white-space: pre-wrap;

  @media (min-width: $table-breakpoint) {
    max-width: 100%;
  }
}

.finish {
  font-weight: 700;
  font-size: px-to-rem(16px);
  line-height: px-to-rem(22px);
  padding: px-to-rem(17px) px-to-rem(39px);
}
