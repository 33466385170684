@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  &Icon {
    width: px-to-rem(28px);
    height: px-to-rem(28px);
  }

  &Balance {
    background: var(--gray-bg-card);
    border-radius: 16px;
    height: px-to-rem(112px);
    max-width: px-to-rem(79px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $table-breakpoint) {
      max-width: px-to-rem(191px);
    }

    @media (min-width: $desktop-breakpoint) {
      max-width: px-to-rem(182px);
      margin: 0 auto;
      justify-content: center;
    }

    &Count {
      font-weight: 600;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(16px);
      color: var(--text-black);
      text-align: center;
      padding-top: px-to-rem(9.5px);

      &Style {
        font-weight: 600;
        font-size: px-to-rem(17px);
        line-height: px-to-rem(22px);
        letter-spacing: px-to-rem(-0.408px);
      }
    }

    &Active {
      background: var(--purple-dark);

      div {
        color: var(--white);
      }
    }

    &Sign {
      font-size: px-to-rem(14px);
      line-height: px-to-rem(20px);
      text-align: center;
      letter-spacing: px-to-rem(-0.24px);
      color: var(--gray);
      padding-top: px-to-rem(4px);
    }
  }
}
