@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../assets/fonts/SFProText/FontsFree-Net-SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
