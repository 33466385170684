@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  padding-top: px-to-rem(20px);
  margin-top: px-to-rem(52px);
  position: relative;

  @media (min-width: $table-breakpoint) {
    padding-top: px-to-rem(64px);
  }
}

.content {
  max-width: px-to-rem(436px);
  width: 100%;
  margin: 0 auto;
  padding-left: px-to-rem(16px);
  padding-right: px-to-rem(16px);

  @media (min-width: $desktop-breakpoint) {
    padding-left: 0;
    padding-right: 0;
    max-width: px-to-rem(486px);
  }
}

.title {
  font-weight: 700;
  color: var(--text-black);
  font-size: px-to-rem(20px);
  line-height: px-to-rem(28px);
  max-width: px-to-rem(147px);

  @media (min-width: $table-breakpoint) {
    font-size: px-to-rem(28px);
    line-height: px-to-rem(36px);
    max-width: initial;
  }

  @media (min-width: $desktop-breakpoint) {
    font-size: px-to-rem(32px);
    line-height: px-to-rem(48px);
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding-top: px-to-rem(32px);
  padding-bottom: px-to-rem(16px);

  @media (min-width: $table-breakpoint) {
    padding-bottom: px-to-rem(96px);
  }

  @media (min-width: $desktop-breakpoint) {
    padding-bottom: px-to-rem(57px);
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--purple-bg);
  border-radius: 50%;
  position: absolute;
  width: px-to-rem(50px);
  height: px-to-rem(50px);
  right: px-to-rem(20px);
  top: px-to-rem(20px);
}

.register {
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);
  color: var(--text-black);
  bottom: px-to-rem(64px);
  padding-top: px-to-rem(32px);
  display: flex;
  flex-direction: column;

  @media (min-width: $table-breakpoint) {
    padding-top: px-to-rem(100px);
    display: initial;
  }

  span {
    color: var(--purple-dark);
  }
}

.textFieldCode {
  padding-top: px-to-rem(24px);
}

.info {
  padding-top: px-to-rem(12px);
  font-size: px-to-rem(12px);
  line-height: px-to-rem(16px);
  color: var(--gray);

  a {
    color: var(--purple-dark);
  }
}

.interests {
  padding-top: px-to-rem(24px);
}

.formSubmit {
  padding: px-to-rem(13px) px-to-rem(32px);
  width: 100%;
  font-weight: 700;
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  margin-top: px-to-rem(40px);
}

.sendCode {
  max-width: px-to-rem(170px);
}

.continueBtn {
  max-width: px-to-rem(156px);
}

.timer {
  align-items: center;
  display: flex;
  padding-top: px-to-rem(12px);
  column-gap: px-to-rem(5px);

  &Info {
    font-size: px-to-rem(12px);
    line-height: px-to-rem(16px);
    color: var(--gray);

    span {
      color: var(--purple-dark);
    }
  }

  &Btn {
    color: var(--purple-dark);
    font-size: px-to-rem(12px);
    line-height: px-to-rem(16px);
  }
}

.errorMsg {
  margin-top: px-to-rem(32px);
}
