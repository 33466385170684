@import 'src/shared/lib/px-to-rem';

.checkbox {
  position: relative;

  &Icon {
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    pointer-events: none;
  }
}

.input {
  visibility: hidden;
  position: absolute;
  top: 0;
  width: px-to-rem(28px);
  height: px-to-rem(28px);
  border-radius: 6px;
}

.label {
  display: flex;
  background: var(--purple-dark);
  border-radius: 6px;
  width: px-to-rem(28px);
  height: px-to-rem(28px);
}
