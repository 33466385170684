@import 'src/shared/lib/px-to-rem';

$base: #263238;
$lite: #fff;
$brand: #5856d6;
$size: px-to-rem(77px);

.loader {
  width: $size;
  height: $size;
  border: px-to-rem(4px) solid #1c1731;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  animation: rotation 1s linear infinite;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $size + px-to-rem(18px);
    height: $size + px-to-rem(18px);
    border-radius: 50%;
    border: px-to-rem(4px) solid;
    border-color: $brand transparent;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
