@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  background: radial-gradient(30.03% 55.93% at 3% 24.59%, #ffffff 0%, rgba(255, 255, 255, 0) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)),
    linear-gradient(360deg, #ffffff 4.92%, rgba(255, 255, 255, 0) 29.1%);
  box-shadow: 0 px-to-rem(16px) px-to-rem(40px) rgba(116, 116, 160, 0.08);
  backdrop-filter: blur(px-to-rem(24px));
  /* Note: backdrop-filter has minimal browser support */
  position: absolute;
  border-radius: px-to-rem(16px);
  right: px-to-rem(320px);
  top: px-to-rem(340px);
  max-width: px-to-rem(333px);
  height: px-to-rem(244px);
  width: 100%;
  display: none;

  @media (min-width: $desktop-breakpoint) {
    display: initial;
  }

  &Avatar {
    position: relative;

    img {
      display: block;
      margin: px-to-rem(46px) auto 0;
      height: px-to-rem(72px);
      width: px-to-rem(72px);
    }

    &Brilliant {
      position: absolute;
      top: 0;
      right: px-to-rem(124px);
    }
  }

  &Close {
    position: absolute;
    right: px-to-rem(12px);
    top: px-to-rem(12px);
  }

  &Title {
    color: var(--text-black);
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 600;
    font-size: px-to-rem(20px);
    line-height: px-to-rem(22px);
    text-align: center;
    letter-spacing: px-to-rem(-0.408px);
    padding-top: px-to-rem(16px);
  }

  &Info {
    font-family: 'SF Pro Text', sans-serif;
    font-size: px-to-rem(15px);
    line-height: px-to-rem(20px);
    text-align: center;
    letter-spacing: px-to-rem(-0.24px);
    margin: 0 auto;
    padding-top: px-to-rem(12px);
    white-space: pre-wrap;
    width: 100%;

    span {
      font-weight: 600;
    }
  }
}
