@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  border-radius: px-to-rem(24px);
  background: #FFF;
  box-shadow: 0 px-to-rem(16px) px-to-rem(44px) 0 rgba(232, 232, 241, 0.72);
  padding-top: px-to-rem(64px);
  margin-top: px-to-rem(52px);

  &ImgContainer {
    max-width: px-to-rem(371px);
    width: 100%;
    max-height: px-to-rem(398px);
    height: 100%;
    margin: 0 auto;

    img {
      display: none;

      @media (min-width: $desktop-breakpoint) {
        display: initial;
        padding-right: px-to-rem(31px);
        object-fit: cover;
        width: 100%;
      }
    }

    &Links {
      display: flex;
      margin: 0 auto;
      justify-content: center;

      @media (min-width: $desktop-breakpoint) {
        display: none;
      }
    }
  }

  h4 {
    color: #1C1C1E;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: px-to-rem(20px);
    font-style: normal;
    font-weight: 700;
    line-height: px-to-rem(28px); /* 140% */
    padding-top: px-to-rem(40px);
    padding-bottom: px-to-rem(120px);

    @media (min-width: $desktop-breakpoint) {
      padding-bottom: 0;
    }
  }

  p {
    display: none;
    color: #7E7E8F;
    text-align: center;
    font-size: px-to-rem(14px);
    font-style: normal;
    font-weight: 400;
    line-height: px-to-rem(22px); /* 157.143% */
    letter-spacing: px-to-rem(-0.078px);
    padding-top: px-to-rem(14px);
    padding-bottom: px-to-rem(120px);

    @media (min-width: $desktop-breakpoint) {
      display: block;
    }
  }
}
