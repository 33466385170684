@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.footer {
  background: radial-gradient(30.03% 55.93% at 3% 24.59%, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    linear-gradient(360deg, #ffffff 4.92%, rgba(255, 255, 255, 0) 29.1%);
  box-shadow: 0 px-to-rem(24px) px-to-rem(56px) rgba(132, 132, 179, 0.12);
  backdrop-filter: blur(px-to-rem(36px));
  padding: px-to-rem(19px) px-to-rem(16px) px-to-rem(8px) px-to-rem(18px);

  &Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: px-to-rem(56px) px-to-rem(60px) px-to-rem(36px) px-to-rem(60px) px-to-rem(36px) px-to-rem(36px);
    max-width: px-to-rem(1100px);
    margin: 0 auto;

    @media (min-width: $table-breakpoint) {
      grid-template-columns: px-to-rem(306px) 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: px-to-rem(16px);
    }

    @media (min-width: $desktop-breakpoint) {
      grid-template-columns: px-to-rem(306px) auto auto auto auto;
      column-gap: px-to-rem(16px);
    }
  }

  @media (min-width: $table-breakpoint) {
    height: px-to-rem(140px);
    padding: px-to-rem(32px) px-to-rem(35px) px-to-rem(32px) px-to-rem(35px);
  }

  @media (min-width: $desktop-breakpoint) {
    height: auto;
    padding: px-to-rem(26px) px-to-rem(181px) px-to-rem(32px) px-to-rem(181px);
  }

  &Address {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(20px);
    letter-spacing: px-to-rem(-0.24px);
    color: var(--gray);
    max-width: px-to-rem(306px);
    padding-bottom: px-to-rem(16px);
    grid-row: 1;

    @media (min-width: $table-breakpoint) {
      max-width: initial;
      padding-bottom: 0;
    }
  }

  &Contact {
    font-size: px-to-rem(14px);
    line-height: px-to-rem(20px);
    letter-spacing: px-to-rem(-0.24px);
    color: var(--text-black);
    padding-bottom: px-to-rem(16px);
    grid-row: 3;

    @media (min-width: $table-breakpoint) {
      padding-bottom: 0;
      grid-row: 1;
      justify-self: end;
    }

    @media (min-width: $desktop-breakpoint) {
      grid-column: 2;
    }
  }

  &Email {
    grid-row: 4;

    @media (min-width: $table-breakpoint) {
      grid-row: 1;
      justify-self: end;
      grid-column: 2/4;
    }

    @media (min-width: $desktop-breakpoint) {
      grid-column: 3;
    }
  }

  &Link {
    color: var(--purple-dark);
    font-size: px-to-rem(14px);
    line-height: px-to-rem(20px);
    letter-spacing: px-to-rem(-0.24px);
    padding-bottom: px-to-rem(16px);
    grid-row: 6;

    @media (min-width: $table-breakpoint) {
      padding-bottom: 0;
      grid-row: 2;
      justify-self: end;
    }

    @media (min-width: $desktop-breakpoint) {
      grid-column: 5;
      grid-row: 1;
    }
  }

  &UserAgreement {
    grid-row: 5;

    @media (min-width: $table-breakpoint) {
      padding-top: 0;
      grid-row: 2;
      justify-self: end;
      padding-right: px-to-rem(30px);
    }

    @media (min-width: $desktop-breakpoint) {
      grid-column: 4;
      grid-row: 1;
    }
  }

  &Creator {
    max-width: px-to-rem(1080px);
    padding-bottom: px-to-rem(40px);
    font-size: px-to-rem(14px);
    line-height: px-to-rem(20px);
    letter-spacing: px-to-rem(-0.24px);
    color: var(--gray);
    grid-row: 2;

    @media (min-width: $table-breakpoint) {
      padding-bottom: 0;
      grid-column: 1;
    }

    @media (min-width: $desktop-breakpoint) {
      padding-top: 0;
      grid-row: 2;
      grid-column: 1;
      padding-bottom: px-to-rem(0px);
    }
  }
}
