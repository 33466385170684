@import 'src/shared/lib/px-to-rem';

.interests {
  padding-top: px-to-rem(24px);

  &Title {
    font-size: px-to-rem(12px);
    line-height: px-to-rem(16px);
    color: var(--gray);
    padding-bottom: px-to-rem(13px);
  }

  &Content {
    display: flex;
    flex-wrap: wrap;
    column-gap: px-to-rem(12px);
    row-gap: px-to-rem(8px);
  }
}
