@import 'src/shared/lib/px-to-rem';

.status {
  align-items: center;
  display: flex;
  padding: px-to-rem(6px) px-to-rem(16px);
  font-size: px-to-rem(12px);
  line-height: px-to-rem(22px);
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: px-to-rem(100px);
  width: fit-content;
  text-align: center;

  &Pending {
    background: var(--pending-status);
    color: var(--pending-clr);
  }

  &Completed {
    background: var(--completed-status);
    color: var(--completed-clr);
  }

  &Cancelled {
    background: var(--gray-bg-card);
    color: var(--cancelled-clr);
  }
}