@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  position: relative;
}

.menu {
  align-items: center;
  background: var(--white);
  box-shadow: 0 px-to-rem(8px) px-to-rem(32px) var(--box-shadow-clr);
  border-radius: px-to-rem(100px);
  padding: px-to-rem(8px) px-to-rem(16px) px-to-rem(8px) 0;
  display: flex;
  height: px-to-rem(40px);
  justify-content: space-between;
  cursor: pointer;
  column-gap: px-to-rem(8px);

  &UserName {
    color: var(--text-black);
    display: none;
    font-weight: 500;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(26px);

    @media (min-width: $table-breakpoint) {
      display: initial;
    }
  }

  &Icon {
    transform: rotate(90deg);
    width: px-to-rem(6px);

    path {
      fill: var(--arrow-clr);
    }
  }
}

.list {
  right: 0;
  display: flex;
  flex-direction: column;
  top: px-to-rem(48px);
  position: absolute;
  background: var(--white);
  box-shadow: 0 px-to-rem(8px) px-to-rem(32px) var(--box-shadow-clr);
  border-radius: px-to-rem(20px);
  z-index: 1;
  width: px-to-rem(191px);

  @media (min-width: $table-breakpoint) {
    width: 100%;
  }

  a, span {
    cursor: pointer;
    padding: px-to-rem(8px) px-to-rem(14px);
    font-weight: 600;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
    color: var(--purple-dark);
    border-bottom: px-to-rem(1px) solid var(--devider-clr);

    &:last-child {
      border-bottom: 0 none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.imgContainer {
  height: px-to-rem(24px);
  width: px-to-rem(24px);
  margin-left: px-to-rem(10px);

  img {
    border-radius: 50%;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
