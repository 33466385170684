@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  &Balance {
    align-items: center;
    display: flex;
    background: var(--gray-bg-card);
    border-radius: px-to-rem(16px);
    padding: px-to-rem(15px) px-to-rem(0px) px-to-rem(15px) px-to-rem(16px);
    column-gap: px-to-rem(16px);
    max-height: px-to-rem(66px);
    height: 100%;

    @media (min-width: $desktop-breakpoint) {
      max-height: px-to-rem(201px);
      max-width: px-to-rem(344px);
      width: 100%;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: initial;
    }

    &Icon {
      width: px-to-rem(35px);
      height: px-to-rem(35px);

      @media (min-width: $desktop-breakpoint) {
        width: px-to-rem(46px);
        height: px-to-rem(46px);
      }
    }

    &Content {
      @media (min-width: $desktop-breakpoint) {
        padding-top: px-to-rem(18px);
        column-gap: px-to-rem(4px);
        display: flex;
        flex-direction: column;
        text-align: center;
      }
    }

    &Count {
      font-weight: 600;
      font-size: px-to-rem(16px);
      line-height: px-to-rem(28px);
      color: var(--text-black);
      text-align: center;

      @media (min-width: $desktop-breakpoint) {
        font-weight: 700;
        font-size: px-to-rem(24px);
        line-height: px-to-rem(32px);
      }
    }

    &Sign {
      font-size: px-to-rem(14px);
      line-height: px-to-rem(20px);
      text-align: center;
      letter-spacing: px-to-rem(-0.24px);
      color: var(--gray);

      @media (min-width: $desktop-breakpoint) {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(20px);
        text-align: center;
        letter-spacing: px-to-rem(-0.24px);
      }
    }
  }
}
