@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  max-width: px-to-rem(1080px);
  margin: 0 auto;
  position: relative;
  padding: 0 px-to-rem(16px);

  @media (min-width: $table-breakpoint) {
    padding: 0 px-to-rem(34px);
  }

  @media (min-width: $desktop-breakpoint) {
    padding: 0;
  }
}
