@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.content {
  position: relative;
}

.mobile {
  position: absolute;
  top: px-to-rem(-123px);
  z-index: 0;
  max-width: px-to-rem(288px);
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (min-width: $desktop-breakpoint) {
    max-width: px-to-rem(479px);
    height: auto;
    position: absolute;
    z-index: -1;
    right: px-to-rem(48px);
    top: px-to-rem(-262px);
    left: initial;
  }

  &Dots {
    align-items: center;
    display: flex;
    justify-content: center;
    column-gap: px-to-rem(12px);
    flex-direction: column;
    row-gap: px-to-rem(12px);
    padding-bottom: px-to-rem(12px);

    @media (min-width: $desktop-breakpoint) {
      display: none;
    }
  }
}

.advertise {
  display: grid;
  height: px-to-rem(616px);
  padding-top: px-to-rem(32px);
  padding-bottom: px-to-rem(56px);
  margin-top: px-to-rem(160px);

  @media (min-width: $table-breakpoint) {
    padding-top: px-to-rem(48px);
    padding-bottom: px-to-rem(64px);
  }

  @media (min-width: $desktop-breakpoint) {
    margin-top: px-to-rem(156px);
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding-bottom: px-to-rem(48px);
    max-height: px-to-rem(248px);
    padding-left: px-to-rem(102px);
    padding-right: px-to-rem(76px);
  }

  &WatchVideo {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    row-gap: px-to-rem(12px);

    span {
      max-width: px-to-rem(204px);

      @media (min-width: $table-breakpoint) {
        max-width: 100%
      }
    }
  }

  &ChangeLotus {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    row-gap: px-to-rem(12px);

    span {
      max-width: px-to-rem(153px);

      @media (min-width: $table-breakpoint) {
        max-width: 100%
      }
    }
  }

  &OutLotus {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    row-gap: px-to-rem(12px);

    span {
      max-width: px-to-rem(171px);

      @media (min-width: $table-breakpoint) {
        max-width: 100%
      }
    }
  }

  &Content {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-top: px-to-rem(32px);

    span {
      font-size: px-to-rem(18px);
      line-height: px-to-rem(26px);
      color: var(--black);
      text-align: center;
      padding: px-to-rem(12px) 0;
      max-width: px-to-rem(204px);
      margin: 0 auto;

      @media (min-width: $desktop-breakpoint) {
        display: none;
      }
    }

    @media (min-width: $desktop-breakpoint) {
      column-gap: px-to-rem(240px);
      flex-direction: row;
    }

    &Circle {
      display: flex;
      align-items: center;
      justify-content: center;
      background: radial-gradient(
          30.03% 55.93% at 3% 24.59%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(0deg, #ffffff, #ffffff),
        linear-gradient(360deg, #ffffff 4.92%, rgba(255, 255, 255, 0) 29.1%);
      box-shadow: 0 px-to-rem(24px) px-to-rem(56px) rgba(132, 132, 179, 0.2);
      backdrop-filter: blur(px-to-rem(36px));
      border-radius: 50%;
      width: px-to-rem(72px);
      height: px-to-rem(72px);
      position: relative;
      margin: 0 auto;

      @media (min-width: $desktop-breakpoint) {
        width: px-to-rem(96px);
        height: px-to-rem(96px);
      }
    }
  }

  &Text {
    flex-direction: column;
    display: none;

    span {
      font-size: px-to-rem(18px);
      line-height: px-to-rem(26px);
      color: var(--black);
      text-align: center;
    }

    @media (min-width: $desktop-breakpoint) {
      display: flex;
      text-align: center;
      justify-content: space-between;
      padding: px-to-rem(24px) px-to-rem(76px) 0 px-to-rem(102px);
      width: 100%;
      flex-direction: row;

      span:nth-child(2) {
        margin-left: px-to-rem(28px);
      }
    }
  }
}

.containerDots {
  display: none;

  @media (min-width: $desktop-breakpoint) {
    display: flex;
    margin-top: px-to-rem(-56px);
  }
}

.dots {
  align-items: center;
}
