@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  display: flex;
  position: relative;
  flex-direction: column;

  @media (min-width: $table-breakpoint) {
    column-gap: px-to-rem(84px);
  }

  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
  }


  &GearIcon {
    align-items: center;
    background: var(--purple-bg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    right: px-to-rem(10px);
    top: px-to-rem(270px);
    height: px-to-rem(50px);
    width: px-to-rem(50px);

    @media (min-width: $desktop-breakpoint) {
      right: px-to-rem(-12px);
      top: px-to-rem(-12px);
      height: px-to-rem(50px);
      width: px-to-rem(50px);
    }
  }

  &Balance {
    display: flex;
    flex-direction: column;
    row-gap: px-to-rem(16px);
    flex: 1;

    @media (min-width: $desktop-breakpoint) {
      max-width: px-to-rem(344px);
    }
  }
}

.paper {
  align-items: center;
  background: var(--main-bg);
  border-radius: px-to-rem(16px);
  max-height: px-to-rem(66px);
  width: 100%;
  display: flex;
  box-shadow: none;
  height: 100%;
  padding: px-to-rem(13px) px-to-rem(11px) px-to-rem(13px) px-to-rem(16px);

  @media (min-width: $table-breakpoint) {
    justify-content: space-between;
    pointer-events: none;
  }

  @media (min-width: $desktop-breakpoint) {
    max-width: px-to-rem(344px);
    max-height: px-to-rem(201px);
    padding: 0 px-to-rem(20px);
    align-content: center;
    flex-direction: column;
    justify-content: center;
  }

  &Content {
    display: flex;
    column-gap: px-to-rem(12px);

    @media (min-width: $desktop-breakpoint) {
      flex-direction: column;
    }
  }
}

.withdraw {
  background: var(--purple-light);
  color: var(--purple-dark);
  display: none;
  box-shadow: none;
  font-weight: 700;
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  pointer-events: all;

  @media (min-width: $table-breakpoint) {
    display: initial;
    padding: px-to-rem(11px) px-to-rem(32px);
  }

  @media (min-width: $desktop-breakpoint) {
    width: 100%;
  }
}

.addMoney {
  display: none;
  font-weight: 700;
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  pointer-events: all;

  @media (min-width: $table-breakpoint) {
    display: initial;
    padding: px-to-rem(11px) px-to-rem(24px);
  }

  @media (min-width: $desktop-breakpoint) {
    width: 100%;
  }
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: px-to-rem(48px);
  position: relative;

  &Coin {
    display: none;

    @media (min-width: $desktop-breakpoint) {
      display: initial;
    }
  }

  &Dots {
    position: absolute;
    display: none;
    column-gap: px-to-rem(8px);

    @media (min-width: $desktop-breakpoint) {
      display: flex;
    }
  }

  &Dot {
    width: px-to-rem(4px);
    height: px-to-rem(4px);
    border-radius: 50%;
    background: var(--gray-circle);
  }

  &Plus {
    width: px-to-rem(40px);
    height: px-to-rem(40px);
    border-radius: 50%;
    background: var(--green-light-bg);
    display: flex;
    align-content: center;
    justify-content: center;

    @media (min-width: $desktop-breakpoint) {
      width: px-to-rem(44px);
      height: px-to-rem(44px);
    }

    svg {
      width: px-to-rem(13px);

      path {
        fill: var(--green-light-clr);
      }
    }
  }

  &Directions {
    background: var(--purple-light);
    width: px-to-rem(40px);
    height: px-to-rem(40px);
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;

    @media (min-width: $desktop-breakpoint) {
      width: px-to-rem(44px);
      height: px-to-rem(44px);
    }

    svg {
      width: px-to-rem(13px);

      path {
        fill: var(--purple-dark);
      }
    }
  }
}

.title {
  line-height: 20px;
  padding-top: px-to-rem(13px);
  padding-bottom: px-to-rem(16px);
  text-align: center;
  font-weight: 600;
  font-size: px-to-rem(16px);
  color: var(--text-black);

  @media (min-width: $desktop-breakpoint) {
    line-height: px-to-rem(28px);
  }
}
