@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  display: flex;
  flex-direction: column;
  margin: 0 px-to-rem(32px) 0 px-to-rem(32px);
  border-bottom: px-to-rem(1px) solid var(--devider-clr);

  &:last-child {
    border: 0 none;
  }

  &Title {
    display: flex;
    justify-content: space-between;
    padding: px-to-rem(24px) 0 px-to-rem(24px) 0;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
    column-gap: px-to-rem(16px);

    @media (min-width: $table-breakpoint) {
      font-size: px-to-rem(18px);
      line-height: px-to-rem(26px);
    }

    &Bold {
      font-weight: 700;
      color: var(--black);
    }

    &Btn {
      cursor: pointer;
      margin-right: px-to-rem(8px);
    }

    svg {
      max-width: px-to-rem(17px);
      width: 100%;

      @media (min-width: $table-breakpoint) {
        max-width: px-to-rem(26px);
      }
    }
  }

  &Content {
    padding: 0 px-to-rem(40px) px-to-rem(24px) 0;
    font-size: px-to-rem(14px);
    line-height: px-to-rem(22px);
    white-space: pre-wrap;

    @media (min-width: $table-breakpoint) {
      font-size: px-to-rem(18px);
      line-height: px-to-rem(26px);
    }
  }
}
