@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  padding-top: px-to-rem(16px);
  padding-bottom: px-to-rem(16px);

  @media (min-width: $desktop-breakpoint) {
    padding-top: px-to-rem(59px);
    height: 100vh;
  }
}

.paper {
  padding: px-to-rem(32px);
}
