@import 'src/shared/lib/px-to-rem';

.container {
  column-gap: px-to-rem(17.15px);
  display: flex;
  flex-wrap: wrap;
  max-width: px-to-rem(372px);
  row-gap: px-to-rem(17.16px);

  a {
    display: flex;
  }
}
