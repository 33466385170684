@import 'src/shared/lib/px-to-rem';

.container {
  position: relative;
  margin: 0 auto;
  width: px-to-rem(64px);
  height: px-to-rem(64px);
}

.image {
  border-radius: 50%;
  pointer-events: none;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.file {
  position: absolute;
  height: px-to-rem(64px);
  width: 64px;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0.48));
  top: 0;
  right: 0;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  &Input {
    visibility: hidden;
    width: px-to-rem(64px);
  }
}
