@import 'src/shared/lib/px-to-rem';

.container {
  display: flex;
  flex-direction: column;
  row-gap: px-to-rem(6px);
}

.label {
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  color: var(--gray);
}

.phoneInput {
  height: px-to-rem(56px) !important;
  width: 100% !important;
  font-size: 1rem !important;
}

.input {
  font-size: px-to-rem(16px);
  line-height: px-to-rem(24px);
  color: var(--text-black);
  padding: px-to-rem(16px) px-to-rem(16px) px-to-rem(16px) px-to-rem(20px);
  border: px-to-rem(1px) solid var(--gray-border-bg);
  border-radius: px-to-rem(8px);
  width: 100%;
  max-height: px-to-rem(56px);

  &::placeholder {
    color: var(--gray-placeholder-clr);
  }
}

.inputContainer {
  position: relative;
  width: 100%;
}

.iconRight {
  position: absolute;
  right: px-to-rem(10px);
  top: 50%;
  transform: translate(-50%, -50%);
}

.errorInput {
  border: px-to-rem(1px) solid var(--error);
}

.error {
  color: var(--error);
  font-size: px-to-rem(14px);
  line-height: px-to-rem(22px);
  padding-top: px-to-rem(6px);
}
