@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.content {
  background: var(--white);
  box-shadow: 0 px-to-rem(12px) px-to-rem(44px) rgba(21, 20, 61, 0.24);
  border-radius: px-to-rem(16px);
  padding: px-to-rem(12px) px-to-rem(20px) px-to-rem(16px) px-to-rem(20px);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--text-black);
  max-width: px-to-rem(403px);
  position: absolute;
  top: px-to-rem(121px);
  z-index: 1;
  left: px-to-rem(16px);
  pointer-events: none;
  margin-right: 16px;

  @media (min-width: $table-breakpoint) {
    left: px-to-rem(236px);
  }
}
