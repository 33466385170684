.container {
  max-width: 636px;
  height: 685px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 39px;
  background: url("../../shared/assets/images/lotus_popup.jpg") no-repeat center center;
  background-size: cover;

  &_popup {
    box-shadow: 0 -3px 25.5px 0px rgba(0, 0, 0, 0.25);
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 15px;
    border-radius: 33px 33px 0 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 153px;
  }

  &_close_icon_container {
    cursor: pointer;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.80);
    backdrop-filter: blur(14px);
    padding: 15px 13px 10px 15px;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 53px;
    height: 53px;
  }

  &_title {
    color: #1C1C1E;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }

  &_action_container {
    display: flex;
    gap: 14px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  &_register {
    border-radius: 100px;
    background: #5856D6;
    box-shadow: 0 4px 12px 0 rgba(88, 86, 214, 0.24);
    color: #FFF;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding: 7px 31px;
    height: 40px;
    display: inline-flex;
  }
}
