@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.container {
  padding-top: px-to-rem(59px);
  padding-bottom: px-to-rem(156px);
  position: relative;
}

.paper {
  margin-top: px-to-rem(24px);
  padding: px-to-rem(20px) px-to-rem(16px) px-to-rem(21px) px-to-rem(16px);

  @media (min-width: $table-breakpoint) {
    padding: px-to-rem(32px);
  }
}
