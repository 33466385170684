@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.chip {
  background: var(--gray-bg);
  border-radius: px-to-rem(8px);
  padding: px-to-rem(6px) px-to-rem(8px) px-to-rem(6px) px-to-rem(8px);
  font-weight: 500;
  font-size: px-to-rem(13px);
  line-height: px-to-rem(18px);
  color: var(--text-black);
  height: px-to-rem(48px);
  display: flex;
  column-gap: px-to-rem(12px);
  align-items: center;
  cursor: pointer;
  width: 100%;

  @media (min-width: $desktop-breakpoint) {
    width: px-to-rem(154px);
  }

  &Disabled {
    cursor: initial;
  }

  &Selected {
    background: var(--purple-dark);
    color: var(--white);
  }

  span {
    text-transform: lowercase;
  }
}
