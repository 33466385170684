@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.user {
  display: flex;
  flex: 2;
  max-width: px-to-rem(486px);
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
}

.form {
  padding-top: px-to-rem(24px);
  display: flex;
  flex-direction: column;
  row-gap: px-to-rem(24px);
}

.errorMsg {
  margin-top: px-to-rem(20px);
}

.saveBtn {
  font-weight: 700;
  font-size: px-to-rem(16px);
  line-height: px-to-rem(22px);
  color: var(--white);
  padding: px-to-rem(17px) px-to-rem(41px) px-to-rem(17px) px-to-rem(40px);
}

.actions {
  display: flex;
  column-gap: px-to-rem(16px);
  padding-top: px-to-rem(40px);
  justify-content: flex-start;
  max-width: px-to-rem(486px);
  width: 100%;
  flex-direction: column;
  row-gap: px-to-rem(16px);

  @media (min-width: $desktop-breakpoint) {
    flex-direction: row;
  }

  &Cancel {
    background: var(--purple-light);
    color: var(--purple-dark);
    font-weight: 700;
    font-size: px-to-rem(16px);
    line-height: px-to-rem(22px);
    box-shadow: none;
    padding: px-to-rem(17px) px-to-rem(57px) px-to-rem(17px) px-to-rem(57px);
  }
}

.userAvatarSpinner {
  transform: scale(0.5);
}
