@import 'src/shared/lib/px-to-rem';
@import 'src/shared/lib/breakpoints';

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: px-to-rem(16px);
  max-width: px-to-rem(1440px);
  margin: 0 auto;

  @media (min-width: $table-breakpoint) {
    padding: px-to-rem(16px) px-to-rem(32px);
  }

  @media (min-width: $desktop-breakpoint) {
    padding: px-to-rem(24px) px-to-rem(32px) 0 px-to-rem(32px);
  }

  &LogoContainer {
    align-items: center;
    column-gap: px-to-rem(12px);
    display: flex;
  }

  &Logo {
    width: px-to-rem(40px);
    height: px-to-rem(40px);

    @media (min-width: $table-breakpoint) {
      width: px-to-rem(64px);
      height: px-to-rem(64px);
    }

    &Title {
      padding-left: px-to-rem(16px);
      padding-right: px-to-rem(10px);
      font-weight: 800;
      font-size: px-to-rem(20px);
      line-height: px-to-rem(32px);
      letter-spacing: px-to-rem(0.716112px);
      color: var(--text-black);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: px-to-rem(16px);
        height: px-to-rem(1px);
        background: var(--arrow-clr);
        transform: rotate(90deg);
        top: px-to-rem(15px);
        right: px-to-rem(-8px);

        @media (min-width: $desktop-breakpoint) {
          display: none;
        }
      }
    }
  }

  &Actions {
    column-gap: px-to-rem(12px);
    display: flex;

    &Login {
      background: var(--purple-light);
      color: var(--purple-dark);
      font-weight: 500;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(26px);
      padding: px-to-rem(7px) px-to-rem(16px);

      @media (min-width: $table-breakpoint) {
        font-size: px-to-rem(14px);
        padding: px-to-rem(7px) px-to-rem(24px);
      }

      svg {
        display: none;

        @media (min-width: $table-breakpoint) {
          display: initial;

          & > path {
            fill: var(--purple-dark);
          }
        }
      }
    }

    &Register {
      background: var(--purple-dark);
      color: var(--white);
      font-weight: 500;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(26px);
      padding: px-to-rem(7px) px-to-rem(16px);
      display: none;

      @media (min-width: $table-breakpoint) {
        display: initial;
        font-size: px-to-rem(14px);
        padding: px-to-rem(7px) px-to-rem(24px);
      }

      svg {
        display: none;
        margin-left: px-to-rem(14px);

        @media (min-width: $table-breakpoint) {
          display: initial;

          & > path {
            fill: var(--purple-super-ligth);
          }
        }
      }
    }

    &Register2 {
      background: var(--purple-dark);
      color: var(--white);
      font-weight: 500;
      font-size: px-to-rem(12px);
      line-height: px-to-rem(26px);
      padding: px-to-rem(7px) px-to-rem(16px);

      @media (min-width: $table-breakpoint) {
        display: none;
        font-size: px-to-rem(14px);
        padding: px-to-rem(7px) px-to-rem(24px);
      }

      svg {
        display: none;

        @media (min-width: $table-breakpoint) {
          display: initial;

          & > path {
            fill: var(--purple-super-ligth);
          }
        }
      }
    }
  }
}

.authMenu {
  display: flex;
  align-items: center;
  column-gap: px-to-rem(32px);

  &Item {
    display: none;

    @media (min-width: $table-breakpoint) {
      display: initial;
      font-weight: 500;
      font-size: px-to-rem(14px);
      line-height: px-to-rem(26px);
      color: var(--text-black);
    }
  }
}
